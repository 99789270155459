import { getallFranchiseACTION, insertUpdateFranchiseAction, getFranchiseACTION, franchiseChangeStatusACTION, franchiseDeleteACTION, getRestaurantACTION, insertUpdateFranchiseRestaurantsAction } from "../../actions/franchise/franchiseAction";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    franchise: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedUserFranchise: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateFranchise: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveFranchise: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteFranchise: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedRestaurants: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateFranchiseRestaurants: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
};
const FranchiseReducer = createSlice({
    name: "Franchise",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetFranchiseDetailsState(state, action) {
            state.selectedUserFranchise = INITIAL_STATE.selectedUserFranchise;
            state.insertUpdateFranchise = INITIAL_STATE.insertUpdateFranchise;
            state.selectedRestaurants = INITIAL_STATE.selectedRestaurants;
        },
    },
    extraReducers: (builder) => {

        // getallFranchiseACTION start

        builder.addCase(getallFranchiseACTION.fulfilled, (state, action) => {
            state.franchise.data = action.payload.data;
            state.franchise.apiMsg.status = action.meta.requestStatus;
            state.franchise.apiMsg.message = "success";
        });
        builder.addCase(getallFranchiseACTION.pending, (state, action) => {
            state.franchise.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallFranchiseACTION.rejected, (state, action) => {
            state.franchise.apiMsg.message = action.error.message;
            state.franchise.apiMsg.status = action.meta.requestStatus;
        });


        // getallFranchiseACTION end
        //------------------------------- getFranchiseACTION start -------------------------

        builder.addCase(getFranchiseACTION.fulfilled, (state, action) => {
            state.selectedUserFranchise.data = action.payload.data;
            state.selectedUserFranchise.apiMsg.status = action.meta.requestStatus;
            state.selectedUserFranchise.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getFranchiseACTION.pending, (state, action) => {
            state.selectedUserFranchise.data = null;
            state.selectedUserFranchise.apiMsg.message = action.meta.requestStatus;;
            state.selectedUserFranchise.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getFranchiseACTION.rejected, (state, action) => {
            state.selectedUserFranchise.apiMsg.message = action.error.message;
            state.selectedUserFranchise.apiMsg.status = action.meta.requestStatus;
        });


        //   getFranchiseACTION end

        // insertUpdateFranchiseAction start

        builder.addCase(
            insertUpdateFranchiseAction.fulfilled,
            (state, action) => {

                state.insertUpdateFranchise.data = action.payload.data.data;
                state.insertUpdateFranchise.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateFranchise.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.franchise.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.franchise.data.records = updatedRecord;
                }
                else {
                    if (state.franchise.data.records.length) {
                        state.franchise.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.franchise.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateFranchiseAction.pending,
            (state, action) => {
                state.insertUpdateFranchise.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateFranchiseAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateFranchise.apiMsg.message = action.error.message;
                state.insertUpdateFranchise.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateFranchiseAction end

        // franchiseChangeStatusACTION start


        builder.addCase(franchiseChangeStatusACTION.fulfilled, (state, action) => {
            state.franchise.data.records = state.franchise.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveFranchise.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveFranchise.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(franchiseChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveFranchise.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(franchiseChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveFranchise.apiMsg.message = action.error.message;
            state.activeInactiveFranchise.apiMsg.status = action.meta.requestStatus;
        });


        // franchiseChangeStatusACTION end



        //------------------------------- franchiseDeleteACTION start -------------------------

        builder.addCase(franchiseDeleteACTION.fulfilled, (state, action) => {

            state.franchise.data.records = state.franchise.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteFranchise.apiMsg.status = action.meta.requestStatus;
            state.deleteFranchise.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(franchiseDeleteACTION.pending, (state, action) => {
            state.deleteFranchise.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(franchiseDeleteACTION.rejected, (state, action) => {
            state.deleteFranchise.apiMsg.message = action.error.message;
            state.deleteFranchise.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- franchiseDeleteACTION end -------------------------



        // getallRestaurantsACTION end
        //------------------------------- getRestaurantsACTION start -------------------------

        builder.addCase(getRestaurantACTION.fulfilled, (state, action) => {
            state.selectedRestaurants.data = action.payload.data;
            state.selectedRestaurants.apiMsg.status = action.meta.requestStatus;
            state.selectedRestaurants.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getRestaurantACTION.pending, (state, action) => {
            state.selectedRestaurants.data = null;
            state.selectedRestaurants.apiMsg.message = action.meta.requestStatus;;
            state.selectedRestaurants.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getRestaurantACTION.rejected, (state, action) => {
            state.selectedRestaurants.apiMsg.message = action.error.message;
            state.selectedRestaurants.apiMsg.status = action.meta.requestStatus;
        });


        //   getFranchiseACTION end


        // insertUpdateFranchiseRestaurantsAction start

        builder.addCase(
            insertUpdateFranchiseRestaurantsAction.fulfilled,
            (state, action) => {

                state.insertUpdateFranchiseRestaurants.data = action.payload.data.data;
                state.insertUpdateFranchiseRestaurants.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateFranchiseRestaurants.apiMsg.message = action.payload.message.message;

                // if (!action.payload.newRecord) {
                //     let updatedRecord = state.franchise.data.records.map(x => {
                //         if (x.id == action.payload.data.data.id) {
                //             return action.payload.data.data;
                //         }
                //         else {
                //             return x;
                //         }
                //     })

                //     state.franchise.data.records = updatedRecord;
                // }
                // else {
                //     if (state.franchise.data.records.length) {
                //         state.franchise.data.records.unshift(action.payload.data.data);
                //     }
                //     else {
                //         state.franchise.data.records.push(action.payload.data.data);
                //     }
                // }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateFranchiseRestaurantsAction.pending,
            (state, action) => {
                state.insertUpdateFranchiseRestaurants.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateFranchiseRestaurantsAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateFranchiseRestaurants.apiMsg.message = action.error.message;
                state.insertUpdateFranchiseRestaurants.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateFranchiseAction end
    },
});
export const { RESET, ResetFranchiseDetailsState } = FranchiseReducer.actions;
export default FranchiseReducer.reducer;