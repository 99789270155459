import { getallReceiptTemplatesACTION, insertUpdateReceiptTemplatesAction, getReceiptTemplateACTION, receiptTemplatesChangeStatusACTION, receiptTemplatesDeleteACTION, getPrintLayoutLookupAction } from "../../actions/receipt-templates/receiptTemplates";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    receiptTemplates: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedReceiptTemplates: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    insertUpdateReceiptTemplates: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveReceiptTemplates: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null
        }
    },
    deleteReceiptTemplates: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null
        }
    },

    printLayoutLookup: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};
const receiptTemplateReducer = createSlice({
    name: "ReceiptTemplates",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetReceiptTemplateState(state, action) {
            state.selectedReceiptTemplates = INITIAL_STATE.selectedReceiptTemplates;
            state.insertUpdateReceiptTemplates = INITIAL_STATE.insertUpdateReceiptTemplates;
            state.printLayoutLookup = INITIAL_STATE.printLayoutLookup
        },
    },
    extraReducers: (builder) => {

        // getallReceiptTemplatesACTION start

        builder.addCase(getallReceiptTemplatesACTION.fulfilled, (state, action) => {
            state.receiptTemplates.data = action.payload.data;
            state.receiptTemplates.apiMsg.status = action.meta.requestStatus;
            state.receiptTemplates.apiMsg.message = "success";
        });
        builder.addCase(getallReceiptTemplatesACTION.pending, (state, action) => {
            state.receiptTemplates.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallReceiptTemplatesACTION.rejected, (state, action) => {
            state.receiptTemplates.apiMsg.message = action.error.message;
            state.receiptTemplates.apiMsg.status = action.meta.requestStatus;
        });


        // getallReceiptTemplatesACTION end
        //------------------------------- getReceiptTemplateACTION start -------------------------

        builder.addCase(getReceiptTemplateACTION.fulfilled, (state, action) => {
            state.selectedReceiptTemplates.data = action.payload.data;
            state.selectedReceiptTemplates.apiMsg.status = action.meta.requestStatus;
            state.selectedReceiptTemplates.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getReceiptTemplateACTION.pending, (state, action) => {
            state.selectedReceiptTemplates.data = null;
            state.selectedReceiptTemplates.apiMsg.message = action.meta.requestStatus;;
            state.selectedReceiptTemplates.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getReceiptTemplateACTION.rejected, (state, action) => {
            state.selectedReceiptTemplates.apiMsg.message = action.error.message;
            state.selectedReceiptTemplates.apiMsg.status = action.meta.requestStatus;
        });


        //   getReceiptTemplateACTION end

        // insertUpdateReceiptTemplatesAction start

        builder.addCase(
            insertUpdateReceiptTemplatesAction.fulfilled,
            (state, action) => {

                state.insertUpdateReceiptTemplates.data = action.payload.data.data;
                state.insertUpdateReceiptTemplates.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateReceiptTemplates.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.receiptTemplates.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.receiptTemplates.data.records = updatedRecord;
                }
                else {
                    if (state.receiptTemplates.data.records.length) {
                        state.receiptTemplates.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.receiptTemplates.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateReceiptTemplatesAction.pending,
            (state, action) => {
                state.insertUpdateReceiptTemplates.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateReceiptTemplatesAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateReceiptTemplates.apiMsg.message = action.error.message;
                state.insertUpdateReceiptTemplates.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateReceiptTemplatesAction end

        // receiptTemplatesChangeStatusACTION start


        builder.addCase(receiptTemplatesChangeStatusACTION.fulfilled, (state, action) => {
            state.receiptTemplates.data.records = state.receiptTemplates.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveReceiptTemplates.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveReceiptTemplates.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(receiptTemplatesChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveReceiptTemplates.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(receiptTemplatesChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveReceiptTemplates.apiMsg.message = action.error.message;
            state.activeInactiveReceiptTemplates.apiMsg.status = action.meta.requestStatus;
        });


        // receiptTemplatesChangeStatusACTION end



        //------------------------------- receiptTemplatesDeleteACTION start -------------------------

        builder.addCase(receiptTemplatesDeleteACTION.fulfilled, (state, action) => {

            state.receiptTemplates.data.records = state.receiptTemplates.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteReceiptTemplates.apiMsg.status = action.meta.requestStatus;
            state.deleteReceiptTemplates.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(receiptTemplatesDeleteACTION.pending, (state, action) => {
            state.deleteReceiptTemplates.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(receiptTemplatesDeleteACTION.rejected, (state, action) => {
            state.deleteReceiptTemplates.apiMsg.message = action.error.message;
            state.deleteReceiptTemplates.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- receiptTemplatesDeleteACTION end -------------------------
        
                builder.addCase(getPrintLayoutLookupAction.fulfilled, (state, action) => {
                    state.printLayoutLookup.data = action.payload.data;
                    state.printLayoutLookup.apiMsg.status = action.meta.requestStatus;
                    state.printLayoutLookup.apiMsg.message = action.payload.message.message;
                });
                builder.addCase(getPrintLayoutLookupAction.pending, (state, action) => {
                    state.printLayoutLookup.data = null;
                    state.printLayoutLookup.apiMsg.message = action.meta.requestStatus;;
                    state.printLayoutLookup.apiMsg.status = action.meta.requestStatus;
                });
                builder.addCase(getPrintLayoutLookupAction.rejected, (state, action) => {
                    state.printLayoutLookup.apiMsg.message = action.error.message;
                    state.printLayoutLookup.apiMsg.status = action.meta.requestStatus;
                });


    },
});
export const { RESET, ResetReceiptTemplateState } = receiptTemplateReducer.actions;
export default receiptTemplateReducer.reducer;